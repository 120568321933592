<template>
  <div class="auth-alts">
    <a
      href="https://www.linkedin.com/company/argeset-yazilim/"
      target="_blank"
      class="auth-alts-linkedin"
    ></a>
    <a
      href="https://www.instagram.com/setxrm_cloud_solutions/"
      target="_blank"
      class="auth-alts-instagram"
    ></a>
    <a
      href="https://www.youtube.com/c/setxrm-cloud-solutions"
      target="_blank"
      class="auth-alts-youtube"
    ></a>
    <a
      href="https://facebook.com/argesetsetcrm"
      target="_blank"
      class="auth-alts-facebook me-0"
    ></a>
  </div>
</template>
<script>
export default {
  name: "SocialIcons",
};
</script>
