<template>
  <div class="btn-group dropup">
    <button
      type="button"
      class="btn btn-secondary dropdown-toggle"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <img
        class="w-15px h-15px rounded-1 me-1"
        width="20"
        height="20"
        :src="
          require(`@/assets/images/flags/${this.$store.state.activeLang}.png`)
        "
        :alt="this.$store.state.activeLang"
      />
      {{ currentLangugeLocale.name }}
    </button>
    <ul class="dropdown-menu">
      <li
        v-for="item in locales.filter((item) => {
          return item.code !== this.$store.state.activeLang;
        })"
        class="cursor-pointer"
        :key="item.code"
      >
        <a
          @click="setLang(item.code)"
          class="dropdown-item"
          :class="{ active: currentLanguage(item.code) }"
        >
          <span class="symbol symbol-20px me-2">
            <img
              class="rounded-1"
              width="20"
              height="20"
              :src="require(`@/assets/images/flags/${item.code}.png`)"
            />
          </span>
          <span class="fs-6">
            {{
              $t(
                "Language." + item.code,
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n/index";
import { getSupportedLocales } from "@/core/i18n/supported-locales";
export default {
  name: "MembershipLocaleSwitcher",
  components: {},
  data() {
    return {
      locales: getSupportedLocales(),
    };
  },
  methods: {
    setLang(code) {
      this.$root.setCurrentUserLanguage(code, true);
    },
  },
  setup() {
    const i18n = useI18n();

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const countries = {
      en: {
        flag: "images/flags/en.png",
        name: "English",
        code: "en",
      },
      tr: {
        flag: "images/flags/tr.png",
        name: "Türkçe",
        code: "tr",
      },
    };

    const currentLangugeLocale = computed(() => {
      return countries[i18n.locale.value];
    });

    return {
      currentLanguage,
      currentLangugeLocale,
      countries,
    };
  },
};
</script>
